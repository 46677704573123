import React, { useState, useEffect, useRef } from "react";

import DataTable from "react-data-table-component";
import { makeStyles } from "@mui/styles";
import {  Input, InputAdornment, Menu, MenuItem } from "@mui/material";
import ActionBar from "components/ActionBar";
import { useNavigate, useLocation } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Box, Card, Divider, IconButton, Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import EditEnrollmentDetailsModal from "components/modals/EditEnrollmentDetailsModal"

import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import { Button } from "react-bootstrap";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import MessagesModal from "components/modals/MessagesModal";
import { SendTimeExtension } from "@mui/icons-material";
import SendEmailModal from "components/modals/SendEmailModal";
import axios from "../../utils/axios";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";




const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
            padding:"0px 4px 0px 4px"
        },
    },
    table: {
        style: {
            borderRadius: 10,
            overflow: "hidden",
        }
    },
    headRow: {
        style: {
            padding: 10,
            backgroundColor: "#FF7676",
            color:"white"
        }
    },
    rows:{
        style:{
            '&:nth-child(odd)': {
                backgroundColor: '#FFF1F1', // Set the desired color for striped rows
              },
        }
    },
    cells: {
        style: {
          padding:"0px 8px 0px 8px", // Adjust the padding as needed
        },
      },
}

export const EnrollmentDetails = (props) => {
    const classes = useStyles();
    const [brokersData, setBrokersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([])
    const [tableData, setTableData] = useState([]);
    const [tableData2, setTableData2] = useState({});
    const [tableData3, setTableData3] = useState({});
    const [filterText, setFilterText] = React.useState('');
    const [filterList, setFilterList] = useState(["a", "b", "c"])
    const [rowData, setRowData] = useState(null)
    const [notesData, setNotesData] = useState([])
    const [notesText, setNotesText] = useState("")
    const [typingText, setTypingText] = useState("")
    const filteredItems = rowData;
    const location = useLocation();
    const navigate = useNavigate()
    const [openEdit,setOpenEdit] = useState(false)
    const [openSendMsg,setOpenSendMsg] = useState(false)
    const [openSendEmail,setOpenSendEmail] = useState(false)
    const [disableAllFunc, setDisableAllFunc] = useState(true);
    const [showRows,setShowRows] = useState(5);
    const [tempStatus,setTempStatus] = useState("");
    const { instance, accounts } = useMsal();
    const [secondDivHeight, setSecondDivHeight] = useState('auto');
    const firstDivRef1 = useRef(null);
    const firstDivRef2 = useRef(null);
    const secondDivRef1 = useRef(null);
    const [backLink, setBackLink] = useState('/enrollment-portal');

    useEffect(()=>{
            setSecondDivHeight(`${firstDivRef1.current.clientHeight + firstDivRef2.current.clientHeight - secondDivRef1.current.clientHeight}px`);
    },[tableData, tableData2, tableData3, window.innerWidth])

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, arr) => {
        setFilterList(arr)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const filterClick = (e) =>{
        handleClose();
    }
    
    const handleTyping = async (e) =>{
        if(e.key === "Enter" || e.type === "click"){
            // let tempArr = [...notesData]
            // tempArr.push(`(${moment(new Date()).format("MM/DD/YYYY")}) ${typingText} - ${rowData?.customerName}`);
            // setNotesData(tempArr)
            try{
                let response2 = await axios.post('/internal/audit-notes',
                    {
                        "user_id":tableData2?.User_ID[0],
                        "notes": e?.msg ? e.msg : typingText
                    },
                    {
                        headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
                    }
                );
                setTypingText("")
                auditlogs(tableData2);
                }catch(error){
                   console.log(error)
                }
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "white",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            fontWeight: 500,
            padding:"8px"
        },
    }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
          backgroundColor: "#FFF1F1",
      },
      // hide last border
      // '&:last-child td, &:last-child th': {
      //     border: 0,
      // },
  }));

    useEffect(() => {
        setColumns([
            {
                name: <div style={{whiteSpace: 'normal',wordWrap: "break-word"}}>Enroll Date</div>,
                selector: row =>row?.Enrollment_Date ? moment(new Date(row?.Enrollment_Date)).format("MM-DD-YY")  : "-",
                center: true,
                minWidth:"80px",
                maxWidth:"80px"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Start Date</div>,
                selector: row =>row?.Start_Date? moment(new Date(row?.Start_Date)).format("MM-DD-YY") : "-",
                center: true,
                minWidth:"80px",
                maxWidth:"80px"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Account #</div>,
                selector: row => row.Account_Number|| "-",
                sortable: true,
                center: true,
                // width: "10%"
                minWidth: "100px",
                maxWidth: "100px"
            },
            {
                name: <div style={{whiteSpace: 'normal',width:"300px"}}>Name</div>,
                selector: row => <div style={{whiteSpace:"normal"}}>{row.Customer_Name || "-"}</div>,
                sortable: true,
                left: true,
                center:true,
                minWidth: "110px",
                maxWidth:"150px"
                // width: "10%"
            },
            // {
            //     name: <div style={{whiteSpace: 'normal'}}>Sales Channel</div>,
            //     selector: row => 
            //     <div style={{whiteSpace:"normal"}}>{row.Sales_Channel}</div> || "-",
            //     left: true,
            //     center:true
            //     // width: "10%"
            // },
            {
                name: 'Broker',
                selector: row => row.Broker_Name || "-",
                left: true,
                center:true,
                minWidth: "100px",
                maxWidth:"100px"
                // width: "10%"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>DF</div>,
                selector: row => <div style={{whiteSpace:"normal"}}>{row.Product_Name}</div> || "-",
                center: true,
                minWidth:"110px",
                maxWidth:"110px"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Deposit Bal</div>,
                selector: row => row.Diposit_Balance || "-",
                center: true,
                minWidth: "100px",
                maxWidth:"100px"
                // width: "10%"
            },
            // {
            //     name: <div style={{whiteSpace: 'normal'}}>S/O History</div>,
            //     selector: row => row.So_History || "-",
            //     center: true,
            //     // width: "10%"
            // },
            {
                name: <div style={{display: "flex", whiteSpace: "normal"}}>
                    SP Status
                </div>,
                selector: row => row.Location_Status || "-",
                left: true,
                center:true,
                minWidth: "120px",
                maxWidth:"120px"
                // width: "15%"
            },
            {
                name: <div style={{display: "flex", whiteSpace: "normal", width:"120px"}}>
                    Document Status
                </div>, 
                selector: row => row.Document_Status || "-",
                left: true,
                center:true,
                minWidth: "120px",
                maxWidth:"120px"
                // width: "15%"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Trust Status</div>,
                selector: row =><div style={{backgroundColor:row.Trust_Status == "Approve"?"#73d49f":row.Trust_Status == "Deny"?"#ff7373":row.Trust_Status == "Review"?"#ffdc73":"white",
                width:"100px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                     {row.Trust_Status == "Approve"?"Approve":row.Trust_Status == "Deny"?"Deny":row.Trust_Status == "Review"?"Review":"-"}
                     </div>,
                sortable: true,
                left: true,
                center:true,
                minWidth:"120px",
                maxWidth:"120px"
                // width: "10%"
            },
            {
                name:  <div style={{display: "flex", whiteSpace: "normal", width:"250px"}}>
                Approval Status
                </div>,
                selector: row =><div style={{backgroundColor:row.Approval_Status == "Approved"?"#73d49f":row.Approval_Status == "Denied"?"#ff7373":row.Approval_Status == "Pending Review"?"#ffdc73":"white",
                width:"100px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                     {/* {row.Approval_Status == "Approve"?"Approved":row.Approval_Status == "Deny"?"Denied":row.Approval_Status == "Reviewed"?"Reviewed":"-"} */}
                     {row.Approval_Status}
                     </div>,
                // sortable: true,
                left: true,
                center:true,
                minWidth:"130px",
                maxWidth:"130px"
                // width: "10%"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Email Sent</div>,
                selector: row =>
                 (row.Email_Send || "-"),
                center: true,
                minWidth: "90px",
                maxWidth:"90px"
                // width: "6%"
            },
            {
                name: 'Actions',
                selector: row => <div style={{display: "flex", justifyContent: "space-around"}}>
                    <EditIcon style={{cursor:"pointer"}} color="primary" onClick={()=>setOpenEdit(true)} />
                </div>,
                // sortable: true,
                center: true,
                minWidth:"70px",
                maxWidth:"70px"
            },
        ]);

    //     setTableData2({"Account":["110057", ""],"ESIID":["45698711321548545455",""],"Customer Name": ["Ken Stokes","Low"],"Address":["16815 Main Street Kety, Texas77497","Medium"]
    //     ,"Phone":["556-445-4489","High"],"Email":["text@yopmail.com","High"],"DOB":["22/11/1999",""],"Credit Score":["910",""],"Language Preference":["English",""]})

    //     setTableData3({"Enrollment Date":["3/2/2023",""],"Start Date":["3/18/2023","Low"],"Switch / Move":["Switching","Low"],"Market Transactions":["3","High"],"IP Address":["192.115.115","Low"],
    //     "Deposit":["Paid","Low"],"Product":["GoodEnergy 24",""],"Product Id":["2040",""],"Product Tier":["Broker Plus",""],"Sales Channel":["Everlast",""]})
    }, [])

    const auditlogs = async(data) =>{
        try{
            if (data && data.User_ID) {
                let response2 = await axios.get('/internal/audit-notes',
                    {
                        headers: {'x-api-key': process.env.REACT_APP_API_KEY},
                        params: {
                            "user_id": data?.User_ID[0]
                        }
                    }
                );
                let tempArr = []
                const options = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                };
                response2.data.auditDetails.map((x) => {
                    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(x?.created_at));
                    tempArr.push({
                        data: `(${formattedDateTime}) by ${accounts[0] ? accounts[0].name : ''}: `,
                        msg: x?.notes
                    });
                })
                response2 && setNotesData(tempArr)
            }
        }catch(error){
           console.log(error)
        }
    }

    const loadEnrollments = async () => {
        try{
            let response1 = await axios.get('/internal/enrollment-portal-details',
                {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                    params:{
                        uuid:location?.state?.row?.Uuid
                    }
                }
            );
            setTableData2(response1?.data?.customerDetails)
            setTableData3(response1?.data?.enrollmentDataDetails)
            setBrokersData(rowData);
            //return response1?.data?.customerDetails;

        }catch(error){
           console.log(error)
           setBrokersData(rowData);
           //return null;
        }
       
        // let sorted = response.data.brokers;
        // function compare(a, b) {
        //     if (Number(a.broker_api_enabled) < Number(b.broker_api_enabled)) {
        //         return 1;
        //     }
        //     if (Number(a.broker_api_enabled) > Number(b.broker_api_enabled)) {
        //         return -1;
        //     }
        //     return 0;
        // }

        // sorted.sort(compare);
        // await setBrokersData(sorted);
    }

    useEffect(() => {
        const init = async ()=>{
            await setRowData(location?.state?.row);
            await loadEnrollments();
            await auditlogs(tableData2);

            if (location?.state?.backLink) {
                await setBackLink(location?.state?.backLink)
            }
            await setDisableAllFunc(false)
        }
        init().then(
            () => {
                //console.log('loaded');

            }
        );
    }, []);

    const editData = (temps) =>{
        let temp = {}
        temp = {...location?.state?.row,Approval_Status: temps}
        setRowData(temp)
    }


    const getSubHeaderComponent = () => {
        return (
          <FilterComponent
            onFilter={(e) => {
              let newFilterText = e.target.value;
              this.filteredItems = fakeUsers.filter(
                (item) =>
                  item.name &&
                  item.name.toLowerCase().includes(newFilterText.toLowerCase())
              );
              this.setState({ filterText: newFilterText });
            }}
            onClear={this.handleClear}
            filterText={this.state.filterText}
          />
        );
    };

    const actionProps = {
        title: "Enrollment Details",
        addTitle: "Add Broker",
        filterTitle: "Filter List",
        hideSearch: true
    };

    const actionsMemo = React.useMemo(() =>
        <>
            <ActionBar actionProps={actionProps} />
        </>
        , []);


    const Item = ({ text }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        const words = text;
        const visibleWords = isExpanded ? words : words.slice(0,70);

        return (
          <div style={{textAlign:"left",fontSize:14, wordBreak: "break-all"}}>
            {visibleWords}
            {words.length > 70 && (
                <Box style={{color:"#FF7373", cursor: "pointer",width:"100px",whiteSpace:"nowrap"}} onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? 'Read Less' : 'Read More'}
                </Box>
            )}
          </div>
        );
    };

    return (
        <>
            {
                (loading || !rowData || rowData.length <= 0) ? (
                    <>
                        loading
                    </>
                ) : (<>
                    <div id="portal"></div>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                    {
                        filterList?.map(item=>
                            <MenuItem key={"dmi" + item} onClick={(e)=>filterClick(e)}>{item}</MenuItem>
                        )
                    }
                    </Menu>
                    <Box style={{width: "90%", display: "flex", justifyContent: "left", marginLeft: "2%", cursor: "pointer"}} onClick={()=>navigate(`${backLink}`)}>
                        <KeyboardDoubleArrowLeftOutlinedIcon /> Back
                    </Box>
                    <Box className={classes.tableContainer}>
                        <Box className={classes.table}>
                            <DataTable
                                columns={columns}
                                data={[filteredItems]}
                                defaultSortAsc={false}
                                defaultSortFieldId={1}
                                fixedHeader
                                fixedHeaderScrollHeight="1000px"
                                // pagination
                                // paginationPerPage={30}
                                // paginationComponentOptions={paginationComponentOptions}
                                striped
                                dense
                                subHeader
                                subHeaderComponent={getSubHeaderComponent}
                                customStyles={customStyleForTable}
                                //subHeaderComponent={subHeaderComponentMemo}
                                actions={actionsMemo}
                            >
                            </DataTable>
                        </Box>
                        <style>
                            {`
                            ::-webkit-scrollbar {
                                width: 10px;
                                height: 10px;
                            }

                            ::-webkit-scrollbar-thumb {
                                border-radius: 3px;
                            }
                            `}
                        </style>
                    </Box>
                </>
                )
            }
            <div className={classes.tableBox}> 
                <div className={classes.tableWidth}>
                    <TableContainer  component={Paper} className="tableContainer" ref={firstDivRef1}>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:"800",fontSize:"16px"}} >CUSTOMER PROFILE</TableCell>
                                    <TableCell style={{fontWeight:"800",fontSize:"16px",textAlign:"end",paddingRight:"50px"}}>RISK</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.providerTableBody}>
                                {Object.keys(tableData2)?.map((row, index) => (
                                    row !== "User_ID" &&
                                     <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row" style={{display:"flex",justifyContent:"space-between"}}>
                                            <div style={{display:"flex"}}>
                                                <div style={{ width: 10, height: 10, backgroundColor: row.colorCode, marginLeft: window.screen.width *0.01 }}></div>
                                                <div style={{marginLeft:"20px",fontWeight:"800",minWidth:window.screen.width *0.04 }}>{row?.replace(/_/g, ' ')}</div>
                                            </div>
                                            <div style={{marginRight:"50px"}}>
                                                {
                                                    tableData2[row][0]
                                                // : <div > <Skeleton variant="rectangular" width={30} height={20} /></div>
                                                }
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell style={{padding:"1px"}}>
                                            <div style={{height:"30px",textAlign:"end",alignItems:"center",display:"flex",justifyContent:'center',width:"100%",
                                            backgroundColor:tableData2[row][1] == "N"?"#ff7373":tableData2[row][1] == "Y"?"#73d49f":tableData2[row][1] == "Medium"?"#ffdc73":""}}>
                                                {
                                                    // tableData2[row][1]
                                                    tableData2[row][1] == "N"?"High":tableData2[row][1] == "Y"?"Low":tableData2[row][1] == "Medium"?"#ffdc73":""
                                                }
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer style={{marginTop:"30px"}}  component={Paper} className="tableContainer" ref={firstDivRef2}>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:"800",fontSize:"16px"}} >ENROLLMENT DATA</TableCell>
                                        <TableCell style={{fontWeight:"800",fontSize:"16px",textAlign:"end",paddingRight:"50px"}}>RISK</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.providerTableBody}>
                                    {Object.keys(tableData3)?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row" style={{display:"flex",justifyContent:"space-between"}}>
                                                <div style={{display:"flex"}}>
                                                    <div style={{ width: 10, height: 10, backgroundColor: row.colorCode, marginLeft: 20,minWidth:window.screen.width *0.01}} />
                                                    <div style={{marginLeft:"20px",fontWeight:"800",minWidth:window.screen.width *0.04}}>{row?.replace(/_/g, ' ')}</div>
                                                </div>
                                                <div style={{marginRight:"100px", textAlign: "end"}}>
                                                    {
                                                        (row.includes("Start_Date") || row.includes("Enrollment_Date")) 
                                                        && tableData3[row][0] ?
                                                        moment(new Date(tableData3[row][0])).format("MM-DD-YY"):
                                                        tableData3[row][0]
                                                    }
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell style={{padding:"1px",width:"18%"}}>
                                                <div style={{height:"30px",textAlign:"end",alignItems:"center",display:"flex",justifyContent:'center',width:"100%", 
                                                backgroundColor:tableData3[row][1] == "N"?"#ff7373":tableData3[row][1] == "Y"?"#73d49f":tableData3[row][1] == "Medium"?"#ffdc73":""}}>
                                                    {
                                                      tableData3[row][1] == "N"?"High":tableData3[row][1] == "Y"?"Low":tableData3[row][1] == "Medium"?"#ffdc73":""
                                                    }
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                    </TableContainer>
                </div> 
                <div className={classes.tableWidth}>
                <Card ref={secondDivRef1}>
                    <Typography style={{fontWeight:"800",fontSize:"16px",padding:"14px"}}>IDENTITY VERIFICATION</Typography>
                    <Divider />
                    <div style={{display:"flex",width:"100%",justifyContent:"space-evenly",padding:"30px", pointerEvents: disableAllFunc ? "none" : "all"}}>
                        <Box style={{padding:"8px",borderRadius:"4px",border:"2px solid #FF7373",color:"#FF7373",width:"28%", cursor: "pointer"}} onClick={()=>{setOpenSendMsg(true)}}>Send Message &nbsp; <SendIcon /></Box>
                        <Box style={{padding:"8px",borderRadius:"4px",border:"2px solid #FF7373",color:"#FF7373",width:"28%", cursor: "pointer"}} onClick={()=>{setOpenSendEmail(true)}}>Send Email &nbsp;  <EmailIcon /></Box>
                        <Box style={{padding:"8px",borderRadius:"4px",border:"2px solid #FF7373",color:"#FF7373",width:"28%", cursor: "pointer"}} onClick={()=>navigate("/enrollment-documents", {state:{row: rowData}})}>Documents &nbsp;  <FolderIcon /></Box>
                    </div>
                </Card>
                <br />
                <Card style={{height: secondDivHeight}}>
                    <Typography style={{fontWeight:"800",fontSize:"16px",padding:"14px"}}>NOTES</Typography>
                    <Divider />
                    <div style={{display:"flex", flexDirection: "column",width:"100%",justifyContent:"space-evenly",padding:"30px"}}>
                    <Input
                        style={{width:"100%"}}
                        placeholder="Typing..."
                        value={typingText}
                        onChange={(e)=>setTypingText(e.target.value)}
                        onKeyDown={(e)=>handleTyping(e)}
                        endAdornment={
                            <InputAdornment position="end">
                                <SendIcon color="primary" style={{cursor: "pointer"}} onClick={(e)=>handleTyping(e)}/>
                            </InputAdornment>
                        }
                    />
                    <br />
                    <Box style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", maxHeight: "555px", overflow: "auto"}}>                    {
                        notesData?.slice(0, showRows)?.map((item,index)=>{
                            return(<>
                                <Item key={index} text={item.data} />
                                <Item key={index} text={item.msg} />
                                <br/>
                            </>)


                        })

                    }
                     {notesData?.length > 5 && <Box style={{color:"#FF7373", cursor: "pointer",width:"100px",whiteSpace:"nowrap"}} onClick={() => setShowRows(showRows<=5?notesData?.length:5)}>
                                    {showRows >5 ? 'Show Recent' : 'Show All'} </Box>}
                    </Box>
                    </div>
                </Card>
                </div>
            </div>
            <EditEnrollmentDetailsModal editData={editData} row={location?.state?.row} open={openEdit} onClose={()=>setOpenEdit(false)}></EditEnrollmentDetailsModal>
            <MessagesModal open={openSendMsg} handleTyping={handleTyping} messageTo={tableData2?.Phone? tableData2?.Phone[0]:""} onClose={()=>setOpenSendMsg(false)}></MessagesModal>
            <SendEmailModal open={openSendEmail} user_id={tableData2?.User_ID? tableData2?.User_ID[0]:''}  messageTo={tableData2?.Phone? tableData2?.Phone[0]:""} handleTyping={handleTyping} mailTo={tableData2?.Email? tableData2?.Email[0]:""} onClose={()=>setOpenSendEmail(false)} utilityName={tableData3?.Uitlity ? tableData3?.Uitlity[0] : ""}></SendEmailModal>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: 30,
    },
    table: {
        // border: "1px solid black"
    },
    menuItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "14px !important",
        minWidth: "135px",
        // filter: "grayscale(100%)",
        paddingTop: 6,
        paddingBottom: 6,
        color: theme.palette.primary.main
    },
    ec_main: {
        width: "100%",
        backgroundColor: "#DCDCDC",
        padding: "14px 5vw",
    },
    ec_container: {
        padding: "15px",
        backgroundColor: "#fff",
        display: "grid",
        borderRadius: 10,
        columnGap: 10,
        rowGap: 10,
        gridTemplateColumns: "repeat(4, 1fr)",
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        border: `1px dashed black`
    },
    ex_item: {
        width: "100%",
        textAlign: "start",
        padding: "8px 15px",
    },
    ex_title: {
        marginBottom: 6,
        fontWeight: "bold",
        fontSize: 16
    },
    ex_value: {
        fontSize: 14
    },
    tableBox:{
        display:"flex",
        width:"100%",
        justifyContent:"space-between",
        padding:"0px 30px 0px 30px",
         [theme.breakpoints.down("md")]: {
            flexDirection:"column"
        },
    },
    tableWidth:{
        height: "auto",
        width:"45%",
        marginBottom:"20px",
         [theme.breakpoints.down("md")]: {
            width:"100%",

        },
    }
}))
