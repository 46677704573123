import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import {makeStyles} from "@mui/styles";
import useCommonStyles from "../CommonStyleClasses";
import {CircularProgress, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import axios from "../../utils/axios";

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
};

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: '55%',
        height: '96vh',
        overflowX: 'hidden',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 8vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        },
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

const AdverseLetterSendModal = ({ open, onClose, tableData, isOpen }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [blob, setBlob] = useState(null);
    if (!open) {
        console.log('not opened...');
        return null
    } else {
      //  setLoading(false);
        console.log('isOpen', isOpen);
        console.log('tableData', tableData);
        console.log(document.getElementById('portal'));
    }

    const rowData = tableData;

    const handleSend = async () => {
        setLoading(true);
        try {
            console.log('Row data:', tableData);
            //return null;

            const prospectIds = tableData.map(row => row.id);

            const response = await axios.post(`/account/profile/premise/docs/sendbatchadv`,
                { prospectIds} ,{
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
            });

            if (response.data.results) {
                console.log('Batch API response:', response.data.results);
                setBlob(response.data.results);
            } else {
                alert('No data returned from the API.');
            }
        } catch (error) {
            console.error('Failed to send adverse letters in batch', error);
            alert('An error occurred while sending the batch of adverse letters.');
        } finally {
            setLoading(false);
            onClose();
        }
    };


    const handleCancel = () => {
        onClose();
    };

    return ReactDOM.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <form>
                        <Typography variant="h6">Adverse Letter Details</Typography>
                        <div>
                            <Typography variant="body1">
                                Total Prospects: {tableData.length}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSend}
                                style={{ marginRight: '8px' }}
                            >
                                Send
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                        {results && (
                            <div>
                                <Typography variant="h6" style={{ marginTop: '20px' }}>
                                    Results:
                                </Typography>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {results.map((result, index) => (
                                        <tr key={index}>
                                            <td>{result.prospectId}</td>
                                            <td>{result.status}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </form>
                )}
            </div>
        </>,
        document.getElementById('portal')
    );
};

export default AdverseLetterSendModal;